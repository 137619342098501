



















import { Prop, Component, Vue } from "vue-property-decorator";

export type TabItem = {
  title: string;
  to: string;
};

@Component
export default class TabRouter extends Vue {
  @Prop({ type: Array, required: true })
  private items!: Array<TabItem>;

  @Prop({ type: Object, default: () => ({}) })
  private data!: object;
}
